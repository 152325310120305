




























import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UserGeneralInformation: () => import('@/components/Profile/UserGeneralInformation.vue'),
    ProfileCharacters: () => import('@/components/Profile/ProfileCharacters.vue')
  }
})
export default class UserView extends Vue {
  userLogsTabs = [
    { name: 'Coupon', component: () => import('@/components/Logs/UserCouponLogs.vue') },
    { name: 'Connection', component: () => import('@/components/Logs/UserConnectionLogs.vue')},
    { name: 'Donation', component: () => import('@/components/Logs/UserDonationLogs.vue') },
    { name: 'Shop', component: () => import('@/components/Logs/UserShopLogs.vue') },
    { name: 'Punishments', component: () => import('@/components/Logs/UserPunishmentsLogs.vue') }
  ]
}
